import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'

import useDomReady from 'src/hooks/window/useDomReady'

import { Wrapper } from 'src/pages/robo-advisor/style'

import pageContext from './pageContext.json'
import languageEN from 'src/pages/robo-advisor/lang/textEN.json'

import {
  Hero,
  UniverseOsPossibilities,
  FAQComponent,
} from '../../robo-advisor/sections/_index'
import RobotAdvantages from '../../robo-advisor/sections/RobotAdvantages/_index'
import HowDoesItWork from '../../robo-advisor/sections/HowDoesItWork/_index'
import Documents from '../../robo-advisor/sections/Documentacao/_index'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QRCode from '../../robo-advisor/assets/images/robo-advisor-qr-code.png'

const PoliticasDePrivacidade = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()

  const oneLinkLP = 'https://intergo.app/c1b04ab3'

  const openModalSeguroPix = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isModalOpen}
        isHrTop
        setIsModal={setIsModalOpen}
        title={languageEN.modal.title}
        subtitle={languageEN.modal.subtitle}
        qrCode={QRCode}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openModalSeguroPix}
        <Hero lang={languageEN.section01} />
        <HowDoesItWork lang={languageEN.section02} />
        <RobotAdvantages lang={languageEN.section03} oneLink={oneLinkLP} setIsModalOpen={setIsModalOpen} />
        <UniverseOsPossibilities lang={languageEN.section04} oneLink={oneLinkLP} setIsModalOpen={setIsModalOpen} />
        <Documents lang={languageEN.section05} />
        <FAQComponent lang={languageEN.faq} />
      </Layout>
    </Wrapper>
  )
}

export default PoliticasDePrivacidade
